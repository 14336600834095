import * as React from "react";
import BuildApp from "../images/build-app.svg";
import Delivery from "../images/delivery.svg";
import SayHi from "../images/say-hi.svg";
import { ChevronRightIcon } from "@heroicons/react/solid";

const contactReasons = [
  {
    id: 1,
    type: "buildApp",
    title: "Build an app",
    subtitle: "????",
    icon: BuildApp,
    iconAlt: "build app icon",
  },
  {
    id: 2,
    type: "softwareDelivery",
    title: "Software delivery consulting",
    subtitle: "???",
    icon: Delivery,
    iconAlt: "software delivery icon",
  },
  {
    id: 3,
    type: "sayHi",
    title: "Just say hi (or other)",
    subtitle: "???",
    icon: SayHi,
    iconAlt: "hi icon",
  },
];

const unusedList = (contactReasons) => (
  <ul className="divide-y divide-gray-200">
  {contactReasons.map((contactReason) => (
    <li key={contactReason.id}>
      <button
        onClick={() => {
          this.selectType(contactReason.type);
        }}
        onKeyDown={() => {
          this.selectType(contactReason.type);
        }}
        className="block w-full hover:bg-gray-50"
      >
        <div className="px-4 py-4 flex items-center sm:px-6">
          <img
            src={contactReason.icon}
            alt={contactReason.iconAlt}
            height={50}
            width={50}
            className="flex-1"
            style={{ maxWidth: "3rem", maxHeight: "3rem" }}
          />
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-sm font-semibold text-gray-800">
              {/* Extend touch target to entire panel */}
              {contactReason.title}
            </h3>
            <p className="mt-1 text-sm text-gray-600 line-clamp-2">
              {/* {contactReason.subtitle} */}
            </p>
          </div>
          <div className="ml-5 flex-shrink-0">
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </button>
    </li>
  ))}
  </ul>
);
export default class ContactTypes extends React.Component {
  constructor(props) {
    super(props);

    this.selectType = this.selectType.bind(this);
  }

  selectType(contactReasonType) {
    window.gtag &&
      window.gtag("event", "select_item", {
        item_list_name: "contact_form_type",
        items: [contactReasonType],
      });
    this.props.onContactTypeSelect(contactReasonType);
  }
  render() {
    return (
      <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
        <h3 className="text-lg font-medium text-gray-900">
          What might you need?
        </h3>
        <div className="flow-root mt-6">
          Hyperfluid Solutions is not currently taking on new clients.
        </div>
      </div>
    );
  }
}
